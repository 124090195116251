.DailySignIn-field {
  margin-bottom: 8px;
}

.DailySignIn-field-label {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}

.class-list-form .pull-right {
    margin-left: 4px;
}

.small-spacing {
  margin-top: 4px;
}

@media (max-width: 1200px) {
    .date-range-position-adjuster {
        float: right !important;
    }
}
