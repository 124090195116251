.FitnessCenterPageIcon {
  display: inline-block;
  float: left;
  margin-bottom: 20px;
  width: 20%;
  text-align: center;
}

.FitnessCenterPageIcon--link {
  color: #000000;
  text-decoration: none;
  border: none;

  &:hover {
    color: #000000;
    text-decoration: none;
  }

  &:focus {
    color: #000000;
    text-decoration: none;
  }
}

.FitnessCenterPageIcon--image {
  width: auto;
  max-height: 150px;
}

.FitnessCenterPageIcon--title {
  display: block;
  font-size: 20px;
}