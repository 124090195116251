.ClickablePanelHeading {
  cursor: pointer;
}

.DailyWorkoutDetail--fields {
  margin-bottom: 10px;
}

.DailyWorkoutDetail--text {
  p, ul {
    margin: 0;
  }
}

.DailyWorkoutDetail--photo {
  margin-bottom: 20px;
  max-width: 80%;
  height: auto;
}

.DailyWorkoutDetail--buttons {
  margin-top: 5px;
}

.DailyWorkoutIndex--link {
  margin-right: 5px;
  border-bottom: none;
  font-size: 18px;
}