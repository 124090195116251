.PrototypeFormContainer {
  margin-bottom: 8px;
}

.PrototypeForm, .SecurityIncidentForm-affectedPros-pro {
  margin-bottom: 18px;
  padding: 18px;
  border: 1px solid #ccc;
}

.SecurityIncidentForm-workerStatements,
.SecurityIncidentForm-securityGuardStatements,
.SecurityIncidentForm-associateStatements {
  margin-bottom: 18px;
}