.ScheduleTable {
  background: #f0f0f0;
  color: #000;

  td {
    border: 1px solid #000;
    min-width: 170px;
  }

  input {
    height: 40px;
  }
}

.ScheduleTable--headRow {
  background: none;
  color: #333;
}

.ScheduleTable--dayHeader {
  background: #999;
  color: #fff;
}