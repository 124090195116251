.ProfileList--header {
  cursor: pointer;
}

.Filters-container {
  margin-left: 8px;

  input[type="checkbox"], input[type="radio"] {
    margin: 3px 3px 0 0;
  }

  .Filters-link {
    border-bottom: none;

    &.active {
      font-weight: bolder;
    }
  }

  .PaginationControls--link {
    margin-right: 5px;
    padding: 8px 16px;

    &.active {
      background-color: #c3202f;
    }
  }
}