.InformationSopDetail-field {
  margin-bottom: 8px;
}

.InformationSopDetail-field-label {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
}

.--delete .fa-trash-o,
.ControlLink--delete {
    color: #d9534f;
}
