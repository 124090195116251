.add-contact-link, .delete-contact-link {
  border: none;
  font-weight: bolder;
  text-decoration: none;
}

#corporate_account_create {
  button {
    margin-left: 5px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}